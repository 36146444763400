import React from 'react'
import {
  Breadcrumbs,
  Hero,
  Page,
  Products,
} from '@components'

const meta = {
  title: 'Protupožarna boja i premaz - Proizvodi | Protupožarna zaštita',
  description: 'Protupožarna boja/premaz ima vrlo široku primjenu, a najčešće se koristi kod protupožarne zaštite nosive čelične konstrukcije i protupožarnu zaštitu drvene konstrukcije.',
}

const Homepage = () => (
  <Page
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/proizvodi/', name: 'Proizvodi'},
        { link: `/proizvodi/protupozarna-boja-i-premaz/`, name: 'Protupožarna boja i premaz'},
      ]}
    />
    <Hero
      description="Protupožarna boja/premaz ima vrlo široku primjenu, a najčešće se koristi kod protupožarne zaštite nosive čelične konstrukcije i protupožarnu zaštitu drvene konstrukcije."
      slim
      subtitle="Protupožarna boja i premaz"
      title="Proizvodi"
    />
    <Products activeCategory="protupozarna-boja-i-premaz" />
  </Page>
)

export default Homepage